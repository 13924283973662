<template lang="pug">
    van-empty(:description="desc1" :image="emptyImg")
</template>

<script>
  const emptyImg = require('../../assert/empty-image-search.png');

  export default {
    props: ['desc'],
    data() {
      return {
        emptyImg,
        desc1: this.desc ? this.desc : '暂无数据'
      }
    }
  }
</script>
