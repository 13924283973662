<template lang="pug">
    .shipping-address
        emptyView(v-if="shippingAddressList.length === 0")
        .list-item(v-else v-for="it in shippingAddressList")
            .top
                p
                    span.name {{it.senderName}}
                    span.ml5 {{it.mobile}}
                    van-tag.tag(type="danger" v-if="it.isDefault").ml5 默认
                p.mt10.address
                    span {{it.province}}{{it.city}}{{it.district}}{{it.address}}
            .bottom
                <!--van-button(plain type="info" size="small") 编辑-->
                <!--van-button.ml5(plain type="primary" size="small") 删除-->
                <!--a.f13 编辑-->
                <!--a.ml10.red.f13 删除-->
        <!--FooterButton(:title="'新增地址'" @save="onAdd")-->
</template>


<script>
  import {Toast} from 'vant';
  import router from '../../router/index'
  import {mapGetters} from "vuex";
  import FooterButton from '../common/footerButton'
  import emptyView from '../common/emptyView'

  export default {
    components:{
      FooterButton,emptyView
    },
    computed: {
      ...mapGetters(['shippingAddressList']),
    },
    data() {
      return {
        chosenAddressId: '1',
        list: [],
      };
    },
    methods: {
      onAdd() {
        router.push('/address/create');
      },
      onEdit(item, index) {
        Toast('编辑地址:' + index);
      },
    },
  }
</script>

<style lang="stylus">
    .shipping-address
        padding 12px
        .list-item
            position: relative;
            padding: 12px;
            background-color: #fff;
            /*border-radius: 8px;*/
            margin-bottom 12px
            min-height 100px
            .top
                .name
                    font-size 16px
                    color:#323233
                .address
                    font-size 13px
                .tag
                    position absolute
                    right 20px
            .bottom
                bottom: 8px;
                position: absolute;
                right: 12px;
            .right
                float: left;
                line-height: 100px;
                .icon
                    font-size 20px
                    color #969799
                    border-left: 1px dotted#ccc;
</style>
